import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import {sessionState} from 'session/session-state.js';
import {alertNotificationUserAPI} from 'api/alert-notification-user.js';
import {DateUtil} from 'utils/date-util.js';
import {ViewMode} from 'models/view-mode.js';

import {FarmSelect} from 'components/FarmSelect.js';
import {ActionTable} from 'components/tables/ActionTable.js';
import {GlobalCss} from 'components/global-css.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
};

class AlertMailIndex extends React.Component {
  constructor() {
    super();

    const columns = [
      {id: 'userName', label: 'ユーザー名'},
      {id: 'mailAddress', label: 'メールアドレス'},
      {id: 'dysstasia', label: '起立困難', type: 'boolean'},
      {id: 'calving', label: '分娩', type: 'boolean'},
      {id: 'updatedAtDisplay', label: '最新更新日時'},
    ];

    this.state = {
      records: [],
      columns: columns,
      showProgress: false
    };
  }

  componentDidMount() {
    this.index();
  }

  createAction() {
    const params = {
      pathname: '/alert_mail/edit',
      state: {
        viewMode: ViewMode.CREATE
      }
    };
    this.props.history.push(params);
  }

  updateAction(record) {
    const params = {
      pathname: '/alert_mail/edit',
      state: {
        id: record.id,
        viewMode: ViewMode.UPDATE
      }
    };
    this.props.history.push(params);
  }

  deleteAction(record) {
    const params = {
      pathname: '/alert_mail/edit',
      state: {
        id: record.id,
        viewMode: ViewMode.DELETE
      }
    };
    this.props.history.push(params);
  }

  index() {
    this.setState({showProgress: true});

    return alertNotificationUserAPI.index(sessionState.currentFarmId()).then((res) => {
      const records = res.data.map((r) => {
        r.updatedAtDisplay = DateUtil.toYYYYMMDDHHmm(r.updatedAt);
        r.canUpdate = true;
        r.canDelete = true;
        return r;
      });

      this.setState(() => ({
        records: records,
        showProgress: false
      }));
    });
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '20px'}}>
        <h2>アラートメール設定</h2>

        <ul className={classes.flexContainer}>
          <li>
            <FarmSelect changeAction={() => this.index()} />
          </li>

          <li style={{marginLeft: '100px'}}>
            <Button variant="contained" color="primary" onClick={() => this.createAction()}>
              登録
            </Button>
          </li>
        </ul>

        <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </div>

        <ActionTable
          records={this.state.records}
          columns={this.state.columns}
          updateAction={(record) => this.updateAction(record)}
          deleteAction={(record) => this.deleteAction(record)}
        />
      </div>
    );
  }
}

AlertMailIndex.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(AlertMailIndex));
export {forExport as AlertMailIndex};
