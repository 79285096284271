import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';

import {sessionState} from 'session/session-state.js';
import {actionLogAPI} from 'api/action-log.js';
import {DateUtil} from 'utils/date-util.js';
import {ActionLog} from 'models/action-log.js';

import {FarmSelect} from 'components/FarmSelect.js';
import {ActionTable} from 'components/tables/ActionTable.js';
import {GlobalCss} from 'components/global-css.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
};

class ActionLogIndex extends React.Component {
  constructor() {
    super();

    const columns = [
      {id: 'createdAtDisplay', label: '実行日時'},
      {id: 'accountName', label: '実行者名'},
      {id: 'notes', label: '備考'},
      {id: 'dataTypeLabel', label: '操作対象'},
      {id: 'actionTypeLabel', label: '操作内容'},
      {id: 'quantity', label: '処理件数'},
      {id: 'rollbacked', label: '巻戻', type: 'boolean'},
    ];

    this.state = {
      records: [],
      columns: columns,
      showProgress: false
    };

    this.myOperaionOnly = true;
  }

  componentDidMount() {
    this.index();
  }

  showDetailAction(record) {
    const params = {
      pathname: '/action_log/detail',
      state: {
        id: record.id
      }
    };
    this.props.history.push(params);
  }

  onChangeMyOperaionOnly(e) {
    this.myOperaionOnly = e.target.checked;
    this.index();
  }

  index() {
    this.setState({showProgress: true});

    const accountId = this.myOperaionOnly ? sessionState.accountId() : null;
    return actionLogAPI.index(sessionState.currentFarmId(), accountId).then((res) => {
      const records = res.data.map((r) => {
        r.createdAtDisplay = DateUtil.toYYYYMMDDHHmm(r.createdAt);
        r.dataTypeLabel = ActionLog.dataTypeLabel(r.dataType);
        r.actionTypeLabel = ActionLog.actionTypeLabel(r.actionType);
        r.canUpdate = ActionLog.canUpdate(r.dataType);
        r.canDelete = false;
        return r;
      });

      this.setState(() => ({
        records: records,
        showProgress: false
      }));
    });
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '20px'}}>
        <h2>操作履歴</h2>

        <ul className={classes.flexContainer}>
          <li>
            <FarmSelect changeAction={() => this.index()} />
          </li>

          <li style={{marginLeft: '100px'}}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.myOperaionOnly}
                  onChange={(e) => this.onChangeMyOperaionOnly(e)}
                  color="primary"
                />
              }
              label="自分の操作履歴のみ表示する"
            />
          </li>
        </ul>

        <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </div>

        <ActionTable
          records={this.state.records}
          columns={this.state.columns}
          updateAction={(record) => this.showDetailAction(record)}
        />
      </div>
    );
  }
}

ActionLogIndex.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(ActionLogIndex));
export {forExport as ActionLogIndex};
