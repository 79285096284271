import * as React from 'react';
import {useState} from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';

import {GlobalCss} from 'components/global-css.js';
import {ErrorMessage} from 'components/ErrorMessage.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
};

const reanalysisTasks = [
  'eartag_activity_reanalysis_importer:run',
  'eartag_activity_reanalysis_importer_parallel:run'
];

const EcsTasksIndex = (props) => {
  const [taskName, setTaskName] = useState('');
  const [loopCount, setLoopCount] = useState('');
  const [notes, setNotes] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const env = process.env.REACT_APP_ENVIRONMENT || 'local';

  const createParams = () => {
    return {
      pathname: '/ecs_tasks/confirm',
      state: {
        taskName: taskName,
        loopCount: loopCount,
        notes: notes,
        environment: env
      }
    };
  };

  const createAction = () => {
    const result = validate();
    if (!result.valid) {
      setErrorMessage(result.errors.join('\n'));
      return;
    } else {
      setErrorMessage('');
    }
    props.history.push(createParams());
  };

  const validate = () => {
    const errors = [];

    if (!taskName) {
      errors.push('タスク名称を選択して下さい');
    }

    return {valid: errors.length === 0, errors: errors};
  };

  const informationStyle = {marginLeft: 80, marginBottom: 20, padding: 4, fontSize: 14, borderRadius: 10, width: 500, backgroundColor: '#EEEEEE'};

  const isReanalysisTask = reanalysisTasks.includes(taskName);

  return (
    <div style={{marginLeft: '20px'}}>
      <h2>ECSタスク実行</h2>
      {env === '本番' && <div style={{color: 'red', fontWeight: 'bold'}}>[注意]本番環境のデータが更新されます</div>}

      <div style={{marginLeft: '40px'}}>
        <ErrorMessage errorMessage={errorMessage} />
      </div>

      <ul className={props.classes.flexContainer}>
        <li>
          <InputLabel htmlFor="ecs-tasks" style={{fontSize: 16, marginLeft: 40, marginBottom: 20, marginTop: 20}}>ECSタスク名称</InputLabel>
          <Select
            inputProps={{name: 'ecs-tasks', id: 'ecs-tasks'}}
            style={{minWidth: 680, marginLeft: 40, marginBottom: 20}}
            onChange={(e) => setTaskName(e.target.value)}
            value={taskName}
          >
            <MenuItem value={'eartag_activity_reanalysis_importer:run'}>活動量分析結果の取り込み(eartag_activity_reanalysis_importer:run)</MenuItem>
            <MenuItem value={'eartag_activity_reanalysis_importer_parallel:run'}>[並列処理版] 活動量分析結果の取り込み(eartag_activity_reanalysis_importer_parallel:run)</MenuItem>
          </Select>
        </li>

        <li style={{marginLeft: '100px'}}>
          <Button variant="contained" color="primary" onClick={() => createAction()}>
            実行
          </Button>
        </li>
      </ul>

      {isReanalysisTask && (
        <div>
          <div className={props.classes.flexContainer}
            style={informationStyle}>
            <ul>
              <li>活動量分析結果の取り込みを選択した場合、実行回数を指定できます。</li>
              <li>指定しない場合は1回のみの実行となります。</li>
            </ul>
          </div>
          <ul className={props.classes.flexContainer} style={{marginLeft: 40, marginBottom: 20}}>
            <li>
              <InputLabel htmlFor="loop-count" style={{fontSize: 16}}>実行回数</InputLabel>
              <Select
                style={{marginTop: 5, width: 100}}
                value={loopCount}
                onChange={(e) => setLoopCount(e.target.value)}
                inputProps={{name: 'loop-count', id: 'loop-count'}}
              >
                {(() => {
                  const list = [];
                  for (let index = 1; index <= 10; index++) {
                    list.push(<MenuItem key={`loop-count-${index}`} value={index}>{index}</MenuItem>);
                  }
                  return list;
                })()}
              </Select>
            </li>
          </ul>
        </div>
      )}

      <ul className={props.classes.flexContainer} style={{marginLeft: 40, marginBottom: 20}}>
        <li>
          <TextField
            type="text"
            label="備考"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            variant="outlined"
            margin="dense"
            fullWidth={true}
            className={props.classes.textAreaField}
            multiline
            rows={5}
            style={{width: 500}}
          />
        </li>
      </ul>
    </div>
  );
};

EcsTasksIndex.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(EcsTasksIndex));
export {forExport as EcsTasksIndex};
