import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import {DatePickerWrapper} from 'components/DatePickerWrapper.js';
import {GlobalCss} from 'components/global-css.js';

import {Farm} from 'models/farm.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  inputArea: {
    height: 500,
    overflow: 'auto',
  },
  textField: {
    width: 300,
  },
  longTextField: {
    width: 500,
  },
  selectField: {
    minWidth: 150,
  },
  dateField: {
    width: 150,
  },
  textAreaField: {
    width: 300,
  },
};

class FarmInput extends React.Component {
  constructor(props) {
    super(props);

    const state = Object.assign({}, props.record);
    state.currentTabIndex = 0;
    state.showMainTab = true;

    this.state = state;

    this.syncState = props.syncState;
  }

  onChangeInput(e, key) {
    this.setState({[key]: e.target.value});
    this.syncState({[key]: e.target.value});
  }

  onChangeDate(key, value) {
    this.setState(() => ({[key]: value}));
    this.syncState({[key]: value});
  }

  onChangeCheckbox(e, key) {
    this.setState({[key]: e.target.checked});
    this.syncState({[key]: e.target.checked});
  }

  onChangeCheckboxWithNextState(key, nextState) {
    this.setState({[key]: nextState});
    this.syncState({[key]: nextState});
  }

  onChangeCertificateAiMenu(menu) {
    menu.checked = !menu.checked;
    const certificateAiMenus = this.state.certificateAiMenus;
    this.setState({certificateAiMenus: certificateAiMenus});
    this.syncState({certificateAiMenus: certificateAiMenus});
  }

  onChangeFarmKind(e) {
    const value = e.target.value;

    let values = {
      farmKind: '肉用',
      farmTypeDairy: false,
      farmTypeBreeding: false,
      farmTypeFattening: true
    };

    if (value === '乳用') {
      values = {
        farmKind: '乳用',
        farmTypeDairy: true,
        farmTypeBreeding: false,
        farmTypeFattening: false
      };
    } else {
      values['useMilking'] = false;
    }

    values['dhiLinkage'] = 'none';

    this.setState(values);
    this.syncState(values);
  }

  onChnageTab(tabIndex) {
    this.setState({
      currentTabIndex: tabIndex,
      showMainTab: tabIndex === 0,
      showFlagTab: tabIndex === 1,
      showActivityTab: tabIndex === 2,
      showOptionsTab: tabIndex === 3,
      showCertificateAiMenusTab: tabIndex === 4,
    });
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '100px'}}>
        <Tabs
          value={this.state.currentTabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, tabIndex) => this.onChnageTab(tabIndex)}
          aria-label="disabled tabs example"
          style={{marginBottom: 20}}
        >
          <Tab label="基本設定" />
          <Tab label="アラート・制御設定"/>
          <Tab label="活動量設定"/>
          <Tab label="選択リスト" />
          <Tab label="授精証明書" />
        </Tabs>

        <MainTab
          classes={classes}
          state={this.state}
          onChangeInput={this.onChangeInput.bind(this)}
          onChangeDate={this.onChangeDate.bind(this)}
          onChangeCheckbox={this.onChangeCheckbox.bind(this)}
          onChangeFarmKind={this.onChangeFarmKind.bind(this)}
          onChangeCheckboxWithNextState={this.onChangeCheckboxWithNextState.bind(this)}
        />

        <FlagTab
          classes={classes}
          state={this.state}
          onChangeInput={this.onChangeInput.bind(this)}
          onChangeCheckbox={this.onChangeCheckbox.bind(this)}
        />

        <ActivityTab
          classes={classes}
          state={this.state}
          onChangeInput={this.onChangeInput.bind(this)}
        />

        <OptionsTab
          classes={classes}
          state={this.state}
          onChangeInput={this.onChangeInput.bind(this)}
        />

        <CertificateAiMenusTab
          classes={classes}
          state={this.state}
          onChangeCertificateAiMenu={this.onChangeCertificateAiMenu.bind(this)}
        />
      </div>
    );
  }
}

FarmInput.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  syncState: PropTypes.func.isRequired,
};

const forExport = withStyles(styles)(FarmInput);
export {forExport as FarmInput};

const MainTab = (props) => {
  const classes = props.classes;
  const state = props.state;
  const onChangeInput = props.onChangeInput;
  const onChangeDate = props.onChangeDate;
  const onChangeCheckbox = props.onChangeCheckbox;
  const onChangeFarmKind = props.onChangeFarmKind;
  const onChangeCheckboxWithNextState = props.onChangeCheckboxWithNextState;

  return (
    <form className={classes.inputArea} hidden={!state.showMainTab} >
      <div style={{marginBottom: 20}}>
        <TextField
          type="text" margin="none" className={classes.longTextField}
          label="牧場名" value={state.farmName} required error={!state.farmName}
          onChange={(e) => onChangeInput(e, 'farmName')}
        />
      </div>

      <ul className={classes.flexContainer} style={{padding: 0, marginBottom: 40}}>
        <li style={{marginRight: 30}}>
          <TextField
            type="text" margin="none" style={{width: 400}}
            label="住所" value={state.farmAddress}
            onChange={(e) => onChangeInput(e, 'farmAddress')}
          />
        </li>

        <li style={{width: 150, marginRight: 30}}>
          <TextField
            type="text" margin="none"
            label="電話番号" value={state.farmPhone}
            onChange={(e) => onChangeInput(e, 'farmPhone')}
          />
        </li>

        <li style={{width: 150}}>
          <DatePickerWrapper
            label="利用開始日"
            date={state.startDate}
            syncState={(value) => onChangeDate('startDate', value)}
          />
        </li>
      </ul>

      <ul className={classes.flexContainer} style={{padding: 0, marginBottom: 20}}>
        <li style={{width: 200}}>
          <InputLabel htmlFor="farm-kind" style={{fontSize: 12}}>牧場種別</InputLabel>
          <Select
            className={classes.selectField}
            value={state.farmKind}
            onChange={(e) => onChangeFarmKind(e)}
            inputProps={{name: 'farmKind', id: 'farm-kind'}}
          >
            <MenuItem value={'乳用'}>乳用</MenuItem>
            <MenuItem value={'肉用'}>肉用</MenuItem>
          </Select>
        </li>

        <li>
          <FarmTypeCheckbox
            visible={state.farmKind === '乳用'}
            label="酪農"
            checked={state.farmTypeDairy}
            onChange={(e) => onChangeCheckbox(e, 'farmTypeDairy')}
          />
        </li>

        <li>
          <FarmTypeCheckbox
            visible={state.farmKind === '乳用'}
            label="搾乳量集計"
            checked={state.useMilking}
            onChange={(e) => onChangeCheckbox(e, 'useMilking')}
            width="150"
          />
        </li>

        <DhiLinkage
          visible={state.farmKind === '乳用'}
          value={state.dhiLinkage}
          onChange={(e) => onChangeInput(e, 'dhiLinkage')}
        />

        <li>
          <FarmTypeCheckbox
            visible={state.farmKind === '肉用'}
            label="肥育"
            checked={state.farmTypeFattening}
            onChange={(e) => onChangeCheckbox(e, 'farmTypeFattening')}
          />
        </li>

        <li>
          <FarmTypeCheckbox
            visible={state.farmKind === '肉用'}
            label="繁殖"
            checked={state.farmTypeBreeding}
            onChange={(e) => onChangeCheckbox(e, 'farmTypeBreeding')}
          />
        </li>
      </ul>

      <ul className={classes.flexContainer} style={{padding: 0, marginBottom: 40}}>
        <li style={{marginRight: 30}}>
          <InputLabel htmlFor="management-style" style={{fontSize: 12}}>経営形態</InputLabel>
          <Select
            className={classes.selectField}
            value={state.managementStyle}
            onChange={(e) => onChangeInput(e, 'managementStyle')}
            inputProps={{name: 'managementStyle', id: 'management-style'}}
          >
            <MenuItem value={'standard'}>自営</MenuItem>
            <MenuItem value={'depositor'}>預託元</MenuItem>
            <MenuItem value={'agent'}>預託先</MenuItem>
          </Select>
        </li>

        <li style={{marginRight: 30}}>
          <InputLabel htmlFor="contracted-plan" style={{fontSize: 12}}>契約プラン</InputLabel>
          <Select
            className={classes.selectField}
            value={state.contractedPlan}
            onChange={(e) => onChangeInput(e, 'contractedPlan')}
            inputProps={{name: 'contractedPlan', id: 'contracted-plan'}}
          >
            <MenuItem value={'full'}>full</MenuItem>
            <MenuItem value={'light'}>light</MenuItem>
            <MenuItem value={'light_feed'}>light_feed</MenuItem>
            <MenuItem value={'trial'}>trial</MenuItem>
            <MenuItem value={'free'}>free</MenuItem>
          </Select>
        </li>

        <li>
          <InputLabel htmlFor="usage-status" style={{fontSize: 12}}>契約・稼働状況</InputLabel>
          <Select
            className={classes.selectField}
            value={state.usageStatus}
            onChange={(e) => onChangeInput(e, 'usageStatus')}
            inputProps={{name: 'usageStatus', id: 'usage-status'}}
          >
            <MenuItem value={'active'}>利用中</MenuItem>
            <MenuItem value={'inactive'}>利用停止</MenuItem>
            <MenuItem value={'non_customer'}>顧客以外</MenuItem>
          </Select>
        </li>
      </ul>

      <ul className={classes.flexContainer} style={{padding: 0, marginBottom: 40}}>
        <li style={{width: 250}}>
          <InputLabel htmlFor="identification-type" style={{fontSize: 12}}>牛個体の識別方法</InputLabel>
          <Select
            className={classes.selectField}
            value={state.identificationType}
            onChange={(e) => onChangeInput(e, 'identificationType')}
            inputProps={{name: 'identificationType', id: 'identification-type'}}
          >
            <MenuItem value={'cow_no'}>牛番号</MenuItem>
            <MenuItem value={'regulation_number_4'}>個体識別番号の拡大4桁</MenuItem>
            <MenuItem value={'regulation_number_5'}>個体識別番号の下5桁</MenuItem>
            <MenuItem value={'name'}>名前</MenuItem>
          </Select>
        </li>

        <AllowDuplicationCowNo
          visible={state.identificationType === 'cow_no'}
          checked={state.allowDuplicationCowNo}
          onChange={(e) => onChangeCheckbox(e, 'allowDuplicationCowNo')}
        />

        <CowNoPattern
          visible={state.identificationType === 'cow_no'}
          value={state.cowNoPattern}
          onChange={(e) => onChangeInput(e, 'cowNoPattern')}
        />

        <li>
          <InputLabel htmlFor="cow-no-matching-pattern" style={{fontSize: 12}}>検索の照合方法</InputLabel>
          <Select
            className={classes.selectField}
            value={state.cowNoMatchingPattern}
            onChange={(e) => onChangeInput(e, 'cowNoMatchingPattern')}
            inputProps={{name: 'cowNoMatchingPattern', id: 'cow-no-matching-pattern'}}
          >
            <MenuItem value={'complete'}>完全一致</MenuItem>
            <MenuItem value={'prefix'}>前方一致</MenuItem>
            <MenuItem value={'fuzzy'}>あいまい検索</MenuItem>
          </Select>
        </li>
      </ul>

      <ul className={classes.flexContainer} style={{padding: 0, marginBottom: 20}}>
        <li style={{width: 250}}>
          <InputLabel htmlFor="calf-management" style={{fontSize: 12}}>分娩時の子牛データ作成</InputLabel>
          <Select
            className={classes.selectField}
            value={state.calfManagement}
            onChange={(e) => onChangeInput(e, 'calfManagement')}
            inputProps={{name: 'calfManagement', id: 'calf-management'}}
          >
            <MenuItem value={'always'}>作成する</MenuItem>
            <MenuItem value={'never'}>作成しない</MenuItem>
            <MenuItem value={'calved'}>分娩毎に判断する</MenuItem>
          </Select>
        </li>
        <li style={{width: 250}}>
          <InputLabel htmlFor="use-calf-management" style={{fontSize: 12}}>子牛管理機能(トライアル運用中)</InputLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.useCalfManagement}
                onChange={(e) => onChangeCheckbox(e, 'useCalfManagement')}
                color="primary"
              />
            }
            label="利用する"
          />
        </li>
      </ul>
      <div style={{marginBottom: 20}}>
        <InputLabel htmlFor="use-sensor-type" style={{fontSize: 12}}>利用センサー種別</InputLabel>
        <Select
          className={classes.selectField}
          value={state.useSensorType}
          onChange={(e) => onChangeInput(e, 'useSensorType')}
          inputProps={{name: 'useSensorType', id: 'use-sensor-type'}}
        >
          <MenuItem value={'use_neck_sensor'}>首センサー</MenuItem>
          <MenuItem value={'use_ear_sensor'}>イヤタグセンサー</MenuItem>
          <MenuItem value={'use_both_sensor'}>併用</MenuItem>
        </Select>
      </div>
      <div className={classes.flexContainer}>
        <li style={{width: 180}}>
          <InputLabel htmlFor="detect-dropout-eartag-sensor" style={{fontSize: 12}}>イヤタグ脱落センサー利用設定</InputLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.detectDropoutEartagSensor}
                onChange={(e) => onChangeCheckbox(e, 'detectDropoutEartagSensor')}
                color="primary"
              />
            }
            label="利用する"
          />
        </li>
        <li style={{width: 690, marginLeft: 15}}>
          <InputLabel htmlFor="ignore-detect-dropout-eartag-sensor" style={{fontSize: 12}}>イヤタグ脱落センサー利用を個別設定する</InputLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={!state.ignoreDetectDropoutEartagSensor}
                onChange={(_e) => {
                  const nextState = !state.ignoreDetectDropoutEartagSensor;
                  onChangeCheckboxWithNextState('ignoreDetectDropoutEartagSensor', nextState);
                }}
                color="primary"
              />
            }
            label="個別で設定する（チェックしない場合、「センサー種別」の値に応じて自動更新します。首センサー利用: false, イヤタグ利用: true）"
          />
        </li>
      </div>
    </form>
  );
};

MainTab.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onChangeCheckbox: PropTypes.func.isRequired,
  onChangeFarmKind: PropTypes.func.isRequired,
  onChangeCheckboxWithNextState: PropTypes.func.isRequired,
};

const FlagTab = (props) => {
  const classes = props.classes;
  const state = props.state;
  const onChangeInput = props.onChangeInput;
  const onChangeCheckbox = props.onChangeCheckbox;

  return (
    <form className={classes.inputArea} hidden={!state.showFlagTab}>
      <div style={{marginBottom: 20}}>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.showHeatIndexFattening}
              onChange={(e) => onChangeCheckbox(e, 'showHeatIndexFattening')}
              color="primary"
            />
          }
          label="肥育雌牛でも発情指数を表示する"
        />
      </div>

      <div style={{marginBottom: 20}}>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.alertDysstasia}
              onChange={(e) => onChangeCheckbox(e, 'alertDysstasia')}
              color="primary"
            />
          }
          label="起立困難アラートを利用する"
        />
      </div>

      <div style={{marginBottom: 20}}>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.useAlertCalving}
              onChange={(e) => onChangeCheckbox(e, 'useAlertCalving')}
              color="primary"
            />
          }
          label="分娩アラートを利用する"
        />
      </div>

      <div style={{marginBottom: 20}}>
        <InputLabel htmlFor="fattening-stage-base-date" style={{fontSize: 12}}>肥育ステージ設定</InputLabel>
        <Select
          className={classes.selectField}
          value={state.fatteningStageBaseDate}
          onChange={(e) => onChangeInput(e, 'fatteningStageBaseDate')}
          inputProps={{name: 'fatteningStageBaseDate', id: 'fattening-stage-base-date'}}
        >
          <MenuItem value={'birthday'}>出生日</MenuItem>
          <MenuItem value={'start_fattening_date'}>肥育開始日</MenuItem>
        </Select>
      </div>

      <div style={{marginBottom: 20}}>
        <TextField
          className={classes.textField}
          type="text" margin="none"
          label="発情アラート対象状態" value={state.heatAlertTargetStates}
          onChange={(e) => onChangeInput(e, 'heatAlertTargetStates')}
          placeholder="未授精,授精,未受胎(－)"
        />
      </div>

      <div style={{marginBottom: 20}}>
        <TextField
          className={classes.textField}
          type="text" margin="none"
          label="搾乳日の切替時刻(HH：MM)" value={state.milkingDailyBoundaryAt}
          onChange={(e) => onChangeInput(e, 'milkingDailyBoundaryAt')}
          placeholder="00:00"
        />
      </div>

      <div style={{marginBottom: 20}}>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.alertLocation}
              onChange={(e) => onChangeCheckbox(e, 'alertLocation')}
              color="primary"
            />
          }
          label="牛群アラートを利用する"
          style={{marginRight: 30}}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={state.autoMovement}
              onChange={(e) => onChangeCheckbox(e, 'autoMovement')}
              color="primary"
            />
          }
          label="自動牛群移動を利用する"
          style={{marginRight: 30}}
        />

        <TextField
          className={classes.textField}
          type="text" margin="none"
          label="自動移動インターバル(HH or 0)" value={state.autoMovementInterval}
          required error={state.autoMovementInterval === ''}
          onChange={(e) => onChangeInput(e, 'autoMovementInterval')}
        />
      </div>

      <div style={{marginBottom: 20}}>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.useUClips}
              onChange={(e) => onChangeCheckbox(e, 'useUClips')}
              color="primary"
            />
          }
          label="U-Clipsを利用する"
        />
      </div>

      <div style={{marginBottom: 20}}>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.useLegacyReportMenu}
              onChange={(e) => onChangeCheckbox(e, 'useLegacyReportMenu')}
              color="primary"
            />
          }
          label="特定牧場専用メニューを利用する"
        />
      </div>

    </form>
  );
};

FlagTab.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onChangeCheckbox: PropTypes.func.isRequired,
};

const ActivityTab = (props) => {
  const classes = props.classes;
  const state = props.state;
  const onChangeInput = props.onChangeInput;

  return (
    <form className={classes.inputArea} hidden={!state.showActivityTab}>
      {Farm.ACTIVITY_COLUMNS.map((item) => {
        return (
          <div key={item.id} style={{marginBottom: 20}}>
            <TextField
              type="text" margin="none" style={{width: 150}}
              label={item.label} value={state[item.id]} required error={!state[item.id]}
              onChange={(e) => onChangeInput(e, item.id)}
            />
          </div>
        );
      })}
    </form>
  );
};

ActivityTab.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  onChangeInput: PropTypes.func.isRequired,
};

const OptionsTab = (props) => {
  const classes = props.classes;
  const state = props.state;
  const onChangeInput = props.onChangeInput;

  return (
    <form className={classes.inputArea} hidden={!state.showOptionsTab}>
      {Farm.USER_DEFINED_OPTIONS.map((item) => {
        return (
          <div key={item.id} style={{marginBottom: 20}}>
            <ul className={classes.flexContainer} style={{padding: 0}}>
              <li style={{width: 180}}>
                <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>{item.label}</InputLabel>
              </li>

              <li>
                <TextField
                  type="text" margin="none" className={classes.textField}
                  value={state[item.id]}
                  variant="outlined" multiline={true} rows={4}
                  onChange={(e) => onChangeInput(e, item.id)}
                />
              </li>
            </ul>
          </div>
        );
      })}
    </form>
  );
};

OptionsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  onChangeInput: PropTypes.func.isRequired,
};

const CertificateAiMenusTab = (props) => {
  const classes = props.classes;
  const state = props.state;
  const onChangeCertificateAiMenu = props.onChangeCertificateAiMenu;

  return (
    <form className={classes.inputArea} hidden={!state.showCertificateAiMenusTab}>
      <List>
        {state.certificateAiMenus.map((menu) => {
          return (
            <ListItem
              key={menu.id}
              onClick={(_) => onChangeCertificateAiMenu(menu)}
              dense
              button
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={menu.checked}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{'aria-labelledby': menu.id}}
                  color="primary"
                />
              </ListItemIcon>
              <ListItemText id={menu.id} primary={menu.label} />
            </ListItem>
          );
        })}
      </List>
    </form>
  );
};

CertificateAiMenusTab.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  onChangeCertificateAiMenu: PropTypes.func.isRequired,
};

const FarmTypeCheckbox = (props) => {
  if (props.visible) {
    const width = props.width || 100;
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={props.checked}
            onChange={props.onChange}
            color="primary"
          />
        }
        label={props.label}
        style={{width: width}}
      />
    );
  } else {
    return null;
  }
};

const AllowDuplicationCowNo = (props) => {
  if (props.visible) {
    return (
      <li>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.checked}
              onChange={props.onChange}
              color="primary" />
          }
          label="重複を許す"
          style={{width: 150}}
        />
      </li>
    );
  } else {
    return null;
  }
};

const CowNoPattern = (props) => {
  if (props.visible) {
    return (
      <li style={{width: 150}}>
        <InputLabel htmlFor="identification-type" style={{fontSize: 12}}>牛番号の形式</InputLabel>
        <Select
          value={props.value}
          onChange={props.onChange}
          inputProps={{name: 'identificationType', id: 'identification-type'}}
          style={{minWidth: 100}}
        >
          <MenuItem value={'number'}>数字</MenuItem>
          <MenuItem value={'alphanumeric'}>英数字</MenuItem>
        </Select>
      </li>
    );
  } else {
    return null;
  }
};

const DhiLinkage = (props) => {
  if (props.visible) {
    return (
      <li style={{width: 150}}>
        <InputLabel htmlFor="dhi-linkage" style={{fontSize: 12}}>牛群検定の連携</InputLabel>
        <Select
          value={props.value}
          onChange={props.onChange}
          inputProps={{name: 'dhiLinkage', id: 'dhi-linkage'}}
          style={{minWidth: 100}}
        >
          <MenuItem value={'none'}>しない</MenuItem>
          <MenuItem value={'hmrt'}>北海道版</MenuItem>
          <MenuItem value={'liaj'}>都府県版</MenuItem>
          <MenuItem value={'both'}>両方</MenuItem>
        </Select>
      </li>
    );
  } else {
    return null;
  }
};
