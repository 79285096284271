import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';

import {sessionState} from 'session/session-state.js';
import {accountAPI} from 'api/account.js';
import {DateUtil} from 'utils/date-util.js';
import {ViewMode} from 'models/view-mode.js';

import {FarmSelect} from 'components/FarmSelect.js';
import {ActionTable} from 'components/tables/ActionTable.js';
import {GlobalCss} from 'components/global-css.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
};

class AccountIndex extends React.Component {
  constructor() {
    super();

    const columns = [
      {id: 'id', label: 'ID'},
      {id: 'name', label: 'ユーザー名'},
      {id: 'email', label: 'メールアドレス'},
      {id: 'role', label: '役割'},
      {id: 'authMethodDisplay', label: 'ログイン方法'},
      {id: 'shared', label: '共有', type: 'boolean'},
      {id: 'deleted', label: '削除済', type: 'boolean'},
      {id: 'updatedAtDisplay', label: '更新日時'},
    ];

    this.state = {
      records: [],
      columns: columns,
      showProgress: false
    };

    this.filterCondition = '';
    this.deletedOnly = false;
  }

  componentDidMount() {
    this.index();
  }

  createAction() {
    const params = {
      pathname: '/account/create',
    };
    this.props.history.push(params);
  }

  updateAction(record) {
    const params = {
      pathname: `/account/${record.id}/${ViewMode.UPDATE}`,
    };
    this.props.history.push(params);
  }

  deleteAction(record) {
    const params = {
      pathname: `/account/${record.id}/${ViewMode.DELETE}`,
    };
    this.props.history.push(params);
  }

  index() {
    this.setState({showProgress: true});

    return accountAPI.index(sessionState.currentFarmId()).then((res) => {
      const records = res.data.map((r) => {
        r.authMethodDisplay = r.authMethod === 'sms' ? '電話番号' : 'メールアドレス';
        r.updatedAtDisplay = DateUtil.toYYYYMMDDHHmm(r.updatedAt);
        r.canUpdate = !r.deleted;
        r.canDelete = !r.deleted;
        return r;
      });

      this.rawRecords = [].concat(records);

      this.setState(() => ({
        records: records,
        showProgress: false
      }));
    });
  }

  onChangeFilterCondition(e) {
    this.filterCondition = e.target.value;
    this.filter();
  }

  onChangeDeletedOnly(e) {
    this.deletedOnly = e.target.checked;
    this.filter();
  }

  filter() {
    let records = [].concat(this.rawRecords);

    if (this.filterCondition) {
      records = records.filter((a) => {
        if (a.name && a.name.includes(this.filterCondition)) {
          return true;
        }
        return a.email && a.email.includes(this.filterCondition);
      });
    }

    if (this.deletedOnly) {
      records = records.filter((a) => a.deleted);
    }

    this.setState(() => ({
      records: records
    }));
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '20px'}}>
        <h2>アカウント</h2>

        <ul className={classes.flexContainer}>
          <li>
            <FarmSelect changeAction={() => this.index()} />
          </li>

          <li style={{marginLeft: '100px'}}>
            <Button variant="contained" color="primary" onClick={() => this.createAction()}>
              登録
            </Button>
          </li>
        </ul>

        <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </div>

        <ul className={classes.flexContainer}>
          <li>
            <div style={{width: 400}}>
              <TextField
                type="text"
                label="ユーザー名またはメールアドレスで絞込"
                value={this.filterCondition}
                onChange={(e) => this.onChangeFilterCondition(e)}
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </div>
          </li>

          <li style={{marginLeft: '30px'}}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.deletedOnly}
                  onChange={(e) => this.onChangeDeletedOnly(e)}
                  color="primary"
                />
              }
              label="削除されたもののみ"
            />
          </li>
        </ul>

        <ActionTable
          records={this.state.records}
          columns={this.state.columns}
          updateAction={(record) => this.updateAction(record)}
          deleteAction={(record) => this.deleteAction(record)}
          linkColumnId="name"
        />
      </div>
    );
  }
}

AccountIndex.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(AccountIndex));
export {forExport as AccountIndex};
