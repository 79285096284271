import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';

import {authorizedFarmAPI} from 'api/authorized-farm.js';
import {DateUtil} from 'utils/date-util.js';
import {ViewMode} from 'models/view-mode.js';

import {ActionTable} from 'components/tables/ActionTable.js';
import {GlobalCss} from 'components/global-css.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
};

class AuthorizedFarmIndex extends React.Component {
  constructor() {
    super();

    const columns = [
      {id: 'accountName', label: 'ユーザー名'},
      {id: 'farmName', label: '所属牧場'},
      {id: 'farmsDisplay', label: '対象牧場'},
      {id: 'updatedAtDisplay', label: '更新日時'},
    ];

    this.state = {
      records: [],
      columns: columns,
      showProgress: false
    };

    this.filterCondition = '';
    this.deletedOnly = false;
  }

  componentDidMount() {
    this.index();
  }

  updateAction(record) {
    const params = {
      pathname: `/authorized_farm/${record.accountId}/${ViewMode.UPDATE}`,
    };
    this.props.history.push(params);
  }

  index() {
    this.setState({showProgress: true});

    return authorizedFarmAPI.index().then((res) => {
      const records = res.data.map((r) => {
        let farmNames = r.farms.join('、');
        if (farmNames.length > 200) {
          farmNames = farmNames.substr(0, 200) + '...';
        }
        let farmsDisplay = `${r.farms.length}件： ${farmNames}`;
        if (r.farms.length === 0) {
          farmsDisplay = '(未設定)';
        }

        r.farmsDisplay = farmsDisplay;
        r.updatedAtDisplay = DateUtil.toYYYYMMDDHHmm(r.updatedAt);
        r.canUpdate = true;
        return r;
      });

      this.rawRecords = [].concat(records);

      this.setState(() => ({
        records: records,
        showProgress: false
      }));
    });
  }

  onChangeFilterCondition(e) {
    this.filterCondition = e.target.value;
    this.filter();
  }

  filter() {
    let records = [].concat(this.rawRecords);

    if (this.filterCondition) {
      records = records.filter((r) => {
        if (r.accountName.includes(this.filterCondition)) {
          return true;
        }
        return r.farms.some((farm) => farm.includes(this.filterCondition));
      });
    }

    this.setState(() => ({
      records: records
    }));
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '20px'}}>
        <h2>牧場アクセス権</h2>

        <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </div>

        <ul className={classes.flexContainer}>
          <li>
            <div style={{width: 400}}>
              <TextField
                type="text"
                label="ユーザー名または対象牧場で絞込"
                value={this.filterCondition}
                onChange={(e) => this.onChangeFilterCondition(e)}
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </div>
          </li>
        </ul>

        <ActionTable
          records={this.state.records}
          columns={this.state.columns}
          updateAction={(record) => this.updateAction(record)}
          linkColumnId="accountName"
        />
      </div>
    );
  }
}

AuthorizedFarmIndex.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(AuthorizedFarmIndex));
export {forExport as AuthorizedFarmIndex};
